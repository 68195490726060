var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers_cash,"items":_vm.cash_type,"items-per-page":10,"item-key":"id","sort-by":"name","mobile-breakpoint":"0","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('h1',[_vm._v("Reporte de Caja")]),_c('v-spacer')],1)]},proxy:true},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.valor).toLocaleString(2)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.showInvoice(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('template',{slot:"body.append"})],2),_c('v-data-table',{staticClass:"elevation-4 mt-6",attrs:{"headers":_vm.headers,"items":_vm.cash_data,"items-per-page":10,"mobile-breakpoint":"0","item-key":"id","sort-by":"name","loading":_vm.loading_status,"loader-height":"10","loading-text":"Cargando ..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('h1',[_vm._v("Documentos")]),_c('v-spacer')],1)]},proxy:true},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.valor).toLocaleString(2)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showInvoice(item)}}},[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('template',{slot:"body.append"})],2),_c('docViewer',{attrs:{"item":_vm.item,"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false},"refresh":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }