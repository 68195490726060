<template>
  <v-container>
    <v-data-table :headers="headers_cash" :items="cash_type" :items-per-page="10" item-key="id" sort-by="name"
      mobile-breakpoint="0" class="elevation-4" :loading="loading_status" loader-height="10"
      loading-text="Cargando ...">
      <template #top>
        <v-toolbar flat>
          <h1>Reporte de Caja</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        <span>{{ parseFloat(item.valor).toLocaleString(2) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="mr-2" @click="showInvoice(item)">
          mdi-magnify
        </v-icon>
      </template>
      <template slot="body.append">
        <!-- <tr>
          <th class="title">Total</th>
          <th class="text-center ">{{ sumTable('cash_type', 'qty') }}</th>
          <th class="text-right ">{{ sumTable('cash_type', 'valor') }}</th>
        </tr> -->
      </template>
    </v-data-table>

    <v-data-table :headers="headers" :items="cash_data" :items-per-page="10" mobile-breakpoint="0" item-key="id"
      sort-by="name" class="elevation-4 mt-6" :loading="loading_status" loader-height="10" loading-text="Cargando ...">
      <template #top>
        <v-toolbar flat>
          <h1>Documentos</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:[`item.valor`]="{ item }">
        <span>{{ parseFloat(item.valor).toLocaleString(2) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="showInvoice(item)">
          mdi-magnify
        </v-icon>
      </template>
      <template slot="body.append">
        <!-- <tr>
          <th class="title">Total</th>
          <th></th>
          <th class="text-center ">{{ sumTable('cash_data', 'quantity') }}</th>
          <th class="text-right ">{{ sumTable('cash_data', 'descuento') }}</th>
          <th class="text-right ">{{ sumTable('cash_data', 'valor') }}</th>
        </tr> -->
      </template>
    </v-data-table>
    <docViewer :item="item" :dialog="dialog" @close="dialog = false" @refresh="refresh()" />
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import docViewer from "../components/doc_viewer.vue";
import createDoc from "../utils/create_doc.js";
export default {
  components: { docViewer },
  data() {
    return {
      loading_status: false,
      headers: [
        {
          text: "Documento ID",
          align: "start",
          sortable: true,
          value: "order_number",
          dataType: "text",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "client_name",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "valor",
          dataType: "number",
        },

        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_cash: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "tipo",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "qty",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "valor",
          dataType: "number",
        },
      ],
      cash_data: [],
      cash_type: [],
      dialog: false,
      item: createDoc(),
    };
  },
  mounted() {
    this.get_report();
  },
  methods: {
    sumTable(table, key) {
      // sum data in give key (property)
      return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
    },
    showInvoice(e) {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dateTo: e.order_date,
        did: e.order_number,
      };
      webserver("get_invoice", qry, (data) => {
        console.log(data);
        this.item = { ...data };
        this.dialog = true;
        this.loading_status = false;
      });
    },
    get_report() {
      this.loading_status = true;

      var qry = {
        store: window.store.store_id,
        date: getToday(),
        userId: window.profile.user_email,
      };
      console.log(qry);
      webserver("get_cashdrawer_report", qry, (data) => {
        console.log(data);
        this.loading_status = false;
        //this.cash_data = data.documents;
        this.cash_type =  data.cash_type;
      });
    },
  },
};
</script>

<style>
</style>
